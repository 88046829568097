<template>
  <div class="page" :loaded="Boolean(podcast)">
    <section class="podcast" v-if="podcast">
      <div class="wrapper">
        <div class="container">
          <img
            :src="podcast.image"
            :alt="podcast.title"
            class="podcast-cover"
          />
          <div class="podcast-details">
            <h3>{{ podcast.title }}</h3>
            <div class="podcast-sources">
              <a
                v-if="podcast.platforms.spotify"
                :href="podcast.platforms.spotify"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="@/assets/images/spotify.svg"
                  alt="Listen on Spotify"
                />
              </a>
              <a
                v-if="podcast.platforms.itunes"
                :href="podcast.platforms.itunes"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="@/assets/images/itunes.svg" alt="Listen on iTunes" />
              </a>
              <a
                v-if="podcast.platforms.soundcloud"
                :href="podcast.platforms.soundcloud"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="@/assets/images/soundcloud.svg" alt="Listen on Soundcloud" />
              </a>
              <a
                v-if="podcast.platforms.youtube"
                :href="podcast.platforms.youtube"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="@/assets/images/youtube.svg" alt="Listen on YouTube" />
              </a>
            </div>
            <div class="podcast-description">
              <p v-html="podcast.description.replace(/\n/g, '<br>')"></p>
            </div>
            <div class="share" v-if="podcast.podcast">
              <span class="share-label">Share {{ podcast.podcast.title }}</span>
              <div class="socials">
                <a
                  :href="`mailto:?subject=${podcast.title}&body=${podcastLink}`"
                  class="social"
                  target="_blank"
                >
                  <Icon icon="envelope" type="far" size="small" />
                </a>
                <a
                  :href="`https://www.facebook.com/sharer/sharer.php?u=${podcastLink}`"
                  class="social"
                  target="_blank"
                >
                  <Icon icon="facebook" size="small" />
                </a>
                <a
                  :href="`https://twitter.com/home?status=${podcast.title} ${podcastLink}`"
                  class="social"
                  target="_blank"
                >
                  <Icon icon="twitter" size="small" />
                </a>
                <a
                  :href="`https://www.linkedin.com/shareArticle?mini=true&url=${podcastLink}&title=${podcast.title}`"
                  class="social"
                  target="_blank"
                >
                  <Icon icon="linkedin" size="small" />
                </a>
                <a
                  ref="podcastLink"
                  :active="copied"
                  :data-clipboard-text="podcastLink"
                  class="social"
                >
                  <Icon icon="link" type="fas" size="small" />
                  <div class="valid">Copied!</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="podcast-episodes" v-if="podcast && episodes">
      <div class="wrapper">
        <div class="container">
          <div class="episodes">
            <div
              class="episode"
              v-for="(episode, index) in episodes"
              :key="episode.guid"
            >
              <img
                class="episode-cover"
                :src="episode.cover || require('@/assets/images/wb_bg_podcast-episode.jpg')"
              >
              <div class="episode-details">
                <h4 class="episode-title">{{ episode.title }}</h4>
                <div class="episode-date">
                  <DateFormat :date="episode.isoDate" format="MMMM D, Y" />
                  by WestBIC
                </div>
                <div class="episode-description" v-html="episode.content"></div>
                <a class="btn medium ghost episode-link" @click="playEpisode(episode, index)">
                  <Icon icon="play" type="fas" />
                  Listen now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BpsImage from "./BpsImage.vue";
import DateFormat from "./DateFormat.vue";
import Icon from "./Icon.vue";
import Clipboard from "clipboard";

export default {
  name: "Podcast",
  components: {
    BpsImage,
    DateFormat,
    Icon,
  },
  data() {
    return {
      podcast: null,
      episodes: null,
      loading: false,
      error: false,
      copied: false,
      success: false,
      newsletterEmail: "",
    };
  },
  computed: {
    db() {
      return this.$store.state.firebase.firestore;
    },
    functions() {
      return this.$store.state.firebase.functions;
    },
    podcastLink() {
      return `https://www.westbic.ie${this.$route.path}`;
    },
    ...mapGetters({
      testMode: "getTest",
    }),
  },
  watch: {
    testMode() {
      this.getItems();
    },
  },
  methods: {
    getItems() {
      if (this.podcastRef) this.podcastRef();
      if (this.episodesRef) this.episodesRef();
      this.podcastRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}pages`)
        .doc("podcast")
        .onSnapshot((podcast) => {
          this.podcast = podcast.exists ? podcast.data() : null;
          if (this.podcast && this.podcast.podcast) {
            this.$nextTick().then(() => {
              const clip = new Clipboard(this.$refs.podcastLink);
              clip.on("success", () => {
                clearTimeout(this.copiedTO);
                this.copied = true;
                this.copiedTO = setTimeout(() => (this.copied = false), 1500);
              });
            });
          }
        });
      this.episodesRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}podcasts`)
        .orderBy("pubDate", "desc")
        .onSnapshot((episodes) => {
          this.episodes = episodes.docs.map((episode) => episode.data());
        });
      this.functions().httpsCallable("getPodcast")({ test: this.testMode });
    },
    playEpisode(episode, index) {
      window.dispatchEvent(
        new CustomEvent("playPodcast", {
          detail: {
            current: episode,
            index,
            episodes: this.episodes,
          },
        })
      );
    },
  },
  mounted: function () {
    this.getItems();
  },
  beforeDestroy: function () {
    if (this.podcastRef) this.podcastRef();
    if (this.episodesRef) this.episodesRef();
  },
};
</script>

<style scoped>
.podcast .container {
  display: flex;
}
.podcast .podcast-details {
  position: relative;
  padding: 64px 96px;
  color: var(--Dark-Blue);
}
.podcast .container .podcast-cover {
  width: 512px;
  object-fit: cover;
  border-radius: 16px;
}
.podcast .podcast-details h3 {
  font-size: 4rem;
  margin-bottom: 16px;
  color: var(--Metallic-Seaweed);
}
.podcast .podcast-details h5 {
  font-size: 2.25rem;
}
.podcast .share {
  position: relative;
  display: flex;
  align-items: center;
  gap: 24px;
  padding-bottom: 32px;
  margin-bottom: 40px;
}
.podcast .share::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 504px;
  max-width: 100%;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
}
.podcast .share .share-label {
  font-size: 1.5rem;
  font-weight: 600;
}
.podcast .podcast-description {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.166666;
  margin-bottom: 40px;
}
.podcast .podcast-sources {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 24px;
  margin: 40px 0;
}
.podcast .podcast-sources a {
  display: block;
}
.podcast .podcast-sources a img {
  height: 40px;
}
.podcast .socials {
  display: flex;
  gap: 8px;
}

.episodes {
  display: flex;
  flex-wrap: wrap;
  margin-top: 96px;
  gap: 64px;
}

.episodes .episode {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(50% - 32px);
  border-radius: 12px;
  transition: all .2s ease-out;
  overflow: hidden;
}
.episodes .episode:hover {
  box-shadow: 0 16px 24px rgba(52, 52, 52, 0.08);
}

.episodes .episode img {
  display: block;
  width: 100%;
  height: 300px;
  object-fit: cover;
  flex-shrink: 0;
}

.episodes .episode .episode-details {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px 48px 48px;
  height: 100%;
}

.episodes .episode h4 {
  font-size: 1.5rem;
  margin-bottom: 8px;
  font-weight: 600;
}

.episodes .episode .episode-date {
  color: var(--Metallic-Seaweed);
}
.episodes .episode .episode-date::after {
  content: '';
  display: block;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin-top: 12px;
}

.episodes .episode .episode-description {
  margin: 24px 0;
  font-size: 1.25rem;
  flex: 1;
}

.episodes .episode .episode-description >>> a {
  color: #0A7E8C;
  text-decoration: underline;
}
.episodes .episode .episode-description >>> ul {
  list-style: circle;
  padding-left: 16px;
}

#app[no-touch] .episodes .episode:hover {
  box-shadow: 0 24px 32px rgba(193, 181, 181, 0.12);
  transform: translateY(-8px);
}

.episodes .episode a.btn {
  align-self: flex-start;
}

@media screen and (max-width: 1280px) {
  .podcast .container .podcast-cover {
    width: 50%;
  }
  .podcast .container .podcast-details {
    padding: 48px;
  }
  .podcast .podcast-details h3 {
    font-size: 3rem;
  }
  .podcast .share {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}

@media screen and (max-width: 1024px) {
  .episodes .episode {
    width: 100%;
    margin: 0 0 48px;
  }
  .podcast .container {
    flex-direction: column;
  }
  .podcast .container .podcast-cover {
    width: 100%;
    height: 320px;
    object-fit: cover;
  }
  .podcast .podcast-details {
    padding: 48px;
  }
}

@media screen and (max-width: 880px) {
  .podcast .container .podcast-cover {
    width: 100%;
  }
  .podcast .container .podcast-details {
    padding: 48px 0;
  }
}

@media screen and (max-width: 600px) {
  .podcast .podcast-details h3 {
    font-size: 2.5rem;
  }
  .podcast .podcast-description {
    font-size: 1.3125rem;
  }
  .podcast .container .podcast-details {
    padding-bottom: 0;
  }
  .episodes .episode .episode-details {
    padding: 24px 24px 48px;
  }
  .episodes .episode .episode-date {
    font-size: 1rem;
  }
  .episodes .episode img {
    height: 220px;
  }
}

@media screen and (max-width: 480px) {
  .podcast .podcast-sources a img {
    height: 32px;
  }
}
</style>
